
import { Component, Vue, Provide } from "vue-property-decorator";
import MortgagesHeader from "@/components/mortgages/MortgagesHeader.vue";
import MortgagesTable from "@/components/mortgages/MortgagesTable.vue";
import { IMortgageData } from "@/models/Mortgages";
import { mapGetters } from "vuex";
import { BUSINESS } from "@/constants";
import { IPagination } from "@/models/Global";

@Component({
  computed: {
    ...mapGetters("mortgages", {
      pagination: "getPagination",
      operations: "getMortgages",
    }),
  },
  components: {
    MortgagesHeader,
    MortgagesTable,
  },
})
export default class Mortgages extends Vue {
  @Provide() curr_business = BUSINESS.mortgages;

  protected pagination!: IPagination;
  protected operations!: IMortgageData[];

  protected getMoney(data: IMortgageData[]): number {
    let money = 0;
    data.filter((mortgage: IMortgageData) => {
      if (
        mortgage.paymentDate &&
        mortgage.paymentDate !== "0000-00-00 00:00:00"
      )
        money += mortgage.commission;
    });
    return money;
  }

  protected get moneyCharged(): number {
    return this.getMoney(this.operations);
  }

  protected get charged(): number {
    return this.operations.filter(
      (mortgage) =>
        mortgage.paymentDate && mortgage.paymentDate !== "0000-00-00 00:00:00"
    ).length;
  }

  protected get inProgress(): number {
    return this.operations.filter((mortgage) => {
      const isLost =
        mortgage.status.keyStatus === "mortgage_lead_status_9" ||
        mortgage.status.keyStatus === "mortgage_lead_status_0";
      return !mortgage.paymentDate && !isLost;
    }).length;
  }
}
