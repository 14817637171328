
import { Component, Vue } from "vue-property-decorator";
import { IPagination, IStatusFilter } from "@/models/Global";
import { IMortgageData } from "@/models/Mortgages";
import { MORTGAGES_STATUS } from "@/constants/mortgages";
import { mapGetters } from "vuex";
import MortgagesWrapper from "@/components/mortgages/MortgagesWrapper.vue";

@Component({
  computed: {
    ...mapGetters("mortgages", {
      operations: "getMortgages",
      loading: "mortgagesLoading",
      pagination: "getPagination",
    }),
  },
  components: { MortgagesWrapper },
})
export default class MortgagesTable extends Vue {
  protected operations!: IMortgageData[];
  protected loading!: boolean;
  protected pagination!: IPagination;

  protected statusList: IStatusFilter[] = MORTGAGES_STATUS;
}
