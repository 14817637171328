
import { Component, Vue, Prop } from "vue-property-decorator";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import { IMortgageData } from "@/models/Mortgages";

@Component({ components: { ProfilePictureWidget } })
export default class MortgagesComments extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgage!: IMortgageData;
}
