
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HiboProgress extends Vue {
  @Prop({ type: Number, required: true })
  protected totalSteps!: number;

  @Prop({ type: Number, required: true })
  protected completedSteps!: number;

  @Prop({ type: String, default: null })
  protected emoji?: string;

  @Prop({ type: Boolean, default: true })
  protected spaced?: boolean;

  protected get completedAll() {
    return this.completedSteps === this.totalSteps;
  }
}
