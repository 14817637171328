
import { Component, Vue, Prop } from "vue-property-decorator";
import ProfilePictureWidget from "@/components/widgets/ProfilePictureWidget.vue";
import { IMortgageData } from "@/models/Mortgages";
import { OPERATION_STATUS_LIST_ID } from "@/constants/mortgages";
import { BRAND_EMAIL_ADDRESS } from "@/constants";

@Component({ components: { ProfilePictureWidget } })
export default class MortgagesContact extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgage!: IMortgageData;

  protected get showBroker() {
    const statusId = this.getStatusId(this.mortgage.status.keyStatus);

    return statusId >= OPERATION_STATUS_LIST_ID.BANK_APPLICATION;
  }

  protected getStatusId(keyStatus: string): number {
    if (keyStatus === "mortgage_lead_status_9")
      return Number("mortgage_lead_status_0".charAt(keyStatus.length - 1));
    return Number(keyStatus.charAt(keyStatus.length - 1));
  }

  protected getFormattedPhone(phone: string): string {
    return phone.startsWith("+34")
      ? [
          phone.slice(0, 3),
          " ",
          phone.slice(3, 6),
          " ",
          phone.slice(6, 9),
          " ",
          phone.slice(9),
        ].join("")
      : phone;
  }

  protected get formattedBrokerEmail(): string | undefined {
    const brokerEmail = this.mortgage.broker.email;

    if (brokerEmail && brokerEmail.includes(BRAND_EMAIL_ADDRESS.HOUSFY)) {
      return brokerEmail.replace(
        BRAND_EMAIL_ADDRESS.HOUSFY,
        BRAND_EMAIL_ADDRESS.HIBO
      );
    }

    return brokerEmail;
  }
}
