
import { Component, Prop, Vue, Inject } from "vue-property-decorator";
import OperationsHeader from "@/components/common/OperationsHeader.vue";
import { operationsHeaderInfo } from "@/models/Global";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters("dashboard", {
      isAlbroksa: "getIsAlbroksa",
    }),
  },
  components: { OperationsHeader },
})
export default class MortgagesHeader extends Vue {
  @Inject() readonly curr_business!: string;
  @Prop({ type: Number, required: true })
  protected totalRequests!: number;

  @Prop({ type: Number, required: true })
  protected inProgress!: number;

  @Prop({ type: Number, required: true })
  protected charged!: number;

  @Prop({ type: Number, required: true })
  protected moneyCharged!: number;

  protected isAlbroksa!: boolean;

  protected get info(): operationsHeaderInfo {
    return {
      inProgress: {
        icon: "icons/houseWithArrow",
        labelKey: "inProgress",
        value: this.inProgress,
      },
      charged: {
        icon: "icons/houseWithCheck",
        labelKey: "billed_f",
        value: this.charged,
      },
      moneyCharged: {
        icon: "icons/euro",
        labelKey: "billed_m",
        value: this.moneyCharged,
      },
    };
  }
}
