var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row items-center"},[_c('div',{staticClass:"flex flex-row items-center flex-grow"},_vm._l((_vm.totalSteps),function(index){return _c('div',{key:index,staticClass:"h-5 flex-grow last:mr-0 rounded",class:{
        'bg-malibu': index <= _vm.completedSteps,
        'bg-edgeWater': index > _vm.completedSteps,
        'mr-1': _vm.spaced,
      }})}),0),(_vm.emoji)?_c('i',{staticClass:"flex items-center justify-center h-30 w-30 rounded-full",class:{
      'md:bg-edgeWater': !_vm.completedAll,
      'md:bg-malibu': _vm.completedAll,
    }},[_vm._v(_vm._s(_vm.emoji))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }