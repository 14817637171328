
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { ISelectOption, IStatusFilter, ITab } from "@/models/Global";
import {
  IMortgageData,
  IMortgagesConstantStatusLists,
  IMortgageStep,
} from "@/models/Mortgages";
import ProfileWidget from "@/components/widgets/ProfileWidget.vue";
import HiboProgress from "@/components/ui/HiboProgress.vue";
import dayjs from "@/plugins/time";
import { MORTGAGES_STATUS, OPERATION_STATUS_LIST } from "@/constants/mortgages";
import VueI18n from "vue-i18n";

@Component({
  components: {
    ProfileWidget,
    HiboProgress,
  },
})
export default class MortgagesTableBody extends Vue {
  @Prop({ type: Array, required: true })
  protected operations!: IMortgageData[];

  @Prop({ type: Array, required: true })
  protected statusList!: IStatusFilter[];

  @Prop({ type: String, required: true })
  protected lostReasonPrefix!: string;

  @Prop({ type: Number, default: 0 })
  protected notificationMortgageId?: number;

  @Prop({ type: Boolean, default: false })
  protected isMaster?: boolean;

  @Watch("notificationMortgageId", { immediate: true })
  openLateralPanelWithComments(value: number) {
    if (value) {
      const autoSelectedMortgage: IMortgageData | undefined =
        this.operations.find((op: IMortgageData) => op.id === value);
      if (autoSelectedMortgage)
        this.setDataAndOpenLateralPanel(autoSelectedMortgage, "comments");
    }
  }

  protected getDate(mortgage: IMortgageData): string {
    const dateFormat: string = this.$t("dateFormat").toString();
    if (mortgage.statusChanges) {
      const currentStatus =
        mortgage.statusChanges[mortgage.statusChanges.length - 1];
      return dayjs(currentStatus.statusDate).format(dateFormat);
    } else return dayjs(mortgage.createdAt).format(dateFormat);
  }

  protected getStatusConfig(status: string): IStatusFilter {
    const source = this.statusList;
    return (
      source.find(
        (item) =>
          item.id ===
          OPERATION_STATUS_LIST[
            status.toUpperCase() as keyof IMortgagesConstantStatusLists
          ]
      ) || source[0]
    );
  }

  protected mortgageIsLostOrWon(status: string): boolean {
    return (
      status === "mortgage_lead_status_8" ||
      status === "mortgage_lead_status_9" ||
      status === "mortgage_lead_status_0"
    );
  }

  protected getNumberCurrentStep(
    mortgage: IMortgageData
  ): string | VueI18n.TranslateResult {
    const statusId = this.getStatusId(mortgage.status.keyStatus);
    if (statusId === 0 || statusId === 9 || statusId === 8)
      return this.$t(this.getLabelKey(mortgage.status.keyStatus));
    else
      return `${statusId}/${this.mortgagesStatusOptions.length - 1} - ${this.$t(
        this.getLabelKey(mortgage.status.keyStatus)
      )}`;
  }

  protected mortgagesStatusOptions: ISelectOption[] = MORTGAGES_STATUS.map(
    (status) => {
      return {
        label: status.name,
        name: status.name,
        value: status.id,
      };
    }
  );

  protected getStatusId(keyStatus: string): number {
    if (keyStatus === "mortgage_lead_status_9")
      return Number("mortgage_lead_status_0".charAt(keyStatus.length - 1));
    return Number(keyStatus.charAt(keyStatus.length - 1));
  }

  protected getLabelKey(mortgageKeyStatus: string): string {
    let id = this.getStatusId(mortgageKeyStatus);
    id = id === 0 ? 9 : id;
    return (
      MORTGAGES_STATUS.find((status: IStatusFilter) => status.statusId === id)
        ?.name || MORTGAGES_STATUS[0].name
    );
  }

  protected stepsList: IMortgageStep[] = [
    {
      id: "mortgage_lead_status_1",
      label: "callWithAgent",
      completed: false,
      completedMsg: "made",
      lostMsg: "requirementsNotFulfilled",
      infoMsg: "callAndOfferBestConditions",
      completedDate: undefined,
      lostDate: undefined,
    },
    {
      id: "mortgage_lead_status_2",
      label: "documentation",
      completed: false,
      completedMsg: "recieved",
      completedDate: undefined,
    },
    {
      id: "mortgage_lead_status_3",
      label: "mortgagePersonalize",
      completed: false,
      completedMsg: "made",
      infoMsg: "allDocumentsBeforeSendBank",
      completedDate: undefined,
    },
    {
      id: "mortgage_lead_status_4",
      label: "bankApplication",
      completed: false,
      completedMsg: "approved",
      lostMsg: "requestRejected",
      infoMsg: "requestBeingEvaluated",
      completedDate: undefined,
      lostDate: undefined,
    },
    {
      id: "mortgage_lead_status_5",
      label: "valuation",
      completed: false,
      completedMsg: "approved",
      completedDate: undefined,
    },
    {
      id: "mortgage_lead_status_6",
      label: "operationInFein",
      completed: false,
      completedMsg: "signed_f",
      adressMsg: "",
      completedDate: undefined,
      futureDate: undefined,
    },
    {
      id: "mortgage_lead_status_7",
      label: "completedFile",
      completed: false,
      completedMsg: "billed_f",
      infoMsg: "receivePayment48h",
      completedDate: undefined,
    },
    {
      id: "mortgage_lead_status_8",
      label: "signAtNotary",
      completed: false,
      completedMsg: "chargedOperation",
      completedDate: undefined,
    },
  ];

  protected tabs: ITab[] = [
    {
      name: "steps",
      label: "status",
      component: "HiboStepsList",
      selected: false,
      available: true,
    },
    {
      name: "documents",
      label: "documents",
      component: "HiboDocuments",
      selected: false,
      available: true,
    },
    {
      name: "comments",
      label: "messages",
      component: "HiboComments",
      selected: false,
      available: true,
    },
    {
      name: "contact",
      label: "contact",
      component: "HiboContact",
      selected: false,
      available: true,
    },
    {
      name: "info",
      label: "info",
      component: "HiboInfo",
      selected: false,
      available: true,
    },
  ];

  protected lateralPanelStatus: string | null = null;

  protected selectedMortgage: IMortgageData | null = null;

  protected selectedMortgagesStatus = 10;

  protected selectedMortgageId: number | null = null;

  protected selectedUserName: string | null = null;

  protected setDataAndOpenLateralPanel(
    mortgage: IMortgageData,
    status: string
  ) {
    if (
      mortgage.status.keyStatus === "mortgage_lead_status_0" ||
      mortgage.status.keyStatus === "mortgage_lead_status_9"
    )
      status = "comments";
    this.lateralPanelStatus = status;
    this.selectedMortgage = mortgage;
    this.selectedMortgageId = mortgage.id;
    this.selectedUserName = mortgage.user.name ?? null;

    this.tabs.map((tab) => {
      tab.selected = status === tab.name;
      if (mortgage.status.keyStatus === "mortgage_lead_status_0")
        tab.available = tab.name === "comments";
      else if (mortgage.status.keyStatus === "mortgage_lead_status_8")
        tab.available = tab.name !== "documents";
      else tab.available = true;
    });

    const currentStep = this.stepsList.findIndex((step) => {
      return step.id === mortgage.status.keyStatus;
    });

    this.stepsList.map((step) => {
      if (currentStep === 5) {
        step.futureDate = mortgage.signatureDate;
        step.adressMsg = mortgage.signaturePlace;
      }
      if (this.stepsList.indexOf(step) < currentStep) {
        step.completed = true;
        const changeDate = mortgage.statusChanges?.find(
          (change) =>
            this.getStatusId(change.statusKey) === this.getStatusId(step.id)
        );
        if (changeDate) step.completedDate = changeDate.statusDate;
      } else {
        step.completed = false;
        step.completedDate = undefined;
        let isRejected = false;
        if (
          this.stepsList.indexOf(step) === currentStep &&
          (mortgage.status.keyStatus === "mortgage_lead_status_9" ||
            mortgage.status.keyStatus === "mortgage_lead_status_0")
        )
          isRejected = true;

        if (!isRejected) step.lostDate = undefined;
      }
    });
    this.handleLateralPanel();
  }

  @Emit("setLateralPanel")
  handleLateralPanel() {
    return {
      tabs: this.tabs,
      lateralPanelStatus: this.lateralPanelStatus,
      selectedMortgage: this.selectedMortgage,
      selectedMortgagesStatus: this.selectedMortgagesStatus,
      selectedMortgageId: this.selectedMortgageId,
      selectedUserName: this.selectedUserName,
      stepsList: this.stepsList,
    };
  }
}
