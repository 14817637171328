
import { Component, Vue, Prop } from "vue-property-decorator";
import { IMortgageData } from "@/models/Mortgages";
import HiboLoader from "@/components/ui/HiboLoader.vue";

@Component({ components: { HiboLoader } })
export default class MortgagesInfo extends Vue {
  @Prop({ type: Object, required: true })
  protected mortgage!: IMortgageData;
  protected extraInfoMortgages = "";
  protected loading = false;

  protected async addMortgageExtraInfo() {
    if (this.isSubmitEnabled) {
      this.loading = true;
      await this.$store.dispatch("mortgages/updateMortgageExtraInfo", {
        currentMortgageId: this.mortgage.id,
        extraInfo: this.extraInfoMortgages,
      });
      this.mortgage.agencyExtraInfo = this.extraInfoMortgages;
      this.loading = false;
    }
  }

  protected get isSubmitEnabled() {
    return (
      this.extraInfoMortgages !== "" &&
      this.extraInfoMortgages !== this.mortgage.agencyExtraInfo
    );
  }

  mounted() {
    this.extraInfoMortgages = this.mortgage.agencyExtraInfo || "";
  }
}
